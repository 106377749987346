import React, { useEffect, useState } from 'react';
import Checkbox from "../../../../UI/Checkbox/Checkbox";
import { connect } from "react-redux";
import { updateSavingsOrInvestmentsData } from "../../../../../store/actions/savingsAndInvestments";
import classes from './SavingsOrInvestmentsSelectorYou.module.scss'

const SavingsOrInvestmentsSelectorComp = ( props ) => {

    const checkboxes = [
        {
            label: `RRSP`,
            name: 'rrspYou'
        },
        {
            label: `Workplace Savings`,
            name: 'workplaceSavingsYou'
        },
        {
            label: `Locked-in`,
            name: 'lockedInYou'
        },
        {
            label: `TFSA`,
            name: 'tfsaYou'
        },
        {
            label: `Non-registered`,
            name: 'nonRegisteredYou'
        }
    ]

    const initState = {
        checkedItems: new Map(),
    }

    const [checked, setChecked] = useState(initState)

    const changeValHandler = (e) => {

        const item = e.target.name;
        const isChecked = e.target.checked;

        setChecked({ checkedItems: checked.checkedItems.set(item, isChecked) })
        props.updateSavingsOrInvestmentsData(checked.checkedItems)
        props.onChange()
    }

    useEffect(() => {
        if (props.rrsp) {
            checked.checkedItems.set('rrspYou', true)
        }

        if (props.workplaceSavings) {
            checked.checkedItems.set('workplaceSavingsYou', true)
        }

        if (props.lockedIn) {
            checked.checkedItems.set('lockedInYou', true)
        }

        if (props.tfsa) {
            checked.checkedItems.set('tfsaYou', true)
        }

        if (props.nonRegistered) {
            checked.checkedItems.set('nonRegisteredYou', true)
        }

        setChecked({ checkedItems: checked.checkedItems })

    },[checked.checkedItems, props])

    return (
        <div className={classes.CheckboxesWrap}>
            {
                checkboxes.map((input, index) => {
                    return (
                        <Checkbox
                            key={index}
                            onChange={changeValHandler}
                            label={input.label}
                            name={input.name}
                            checked={ checked.checkedItems.get(input.name) }
                        />
                    )
                })
            }
        </div>
    )
};

function mapStateToProps(state) {
    return {
        rrsp: state.savingsAndInvestments.you.rrsp.exist,
        workplaceSavings: state.savingsAndInvestments.you.workplaceSavings.exist,
        lockedIn: state.savingsAndInvestments.you.lockedIn.exist,
        tfsa: state.savingsAndInvestments.you.tfsa.exist,
        nonRegistered: state.savingsAndInvestments.you.nonRegistered.exist
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateSavingsOrInvestmentsData : checkedItems => dispatch(updateSavingsOrInvestmentsData(checkedItems))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavingsOrInvestmentsSelectorComp);