import React, { useCallback, useEffect, useState } from 'react';
import Layout from "../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import SavingsOrInvestmentsSelectorComp
    from "../../../components/ContentRow/Step3/SavingsOrInvestmentsSelector/SavingsOrInvestmentsSelectorYou/SavingsOrInvestmentsSelectorComp";
import SavingsOrInvestmentsSelectorSpouseComp
    from "../../../components/ContentRow/Step3/SavingsOrInvestmentsSelector/SavingsOrInvestmentsSelectorSpouse/SavingsOrInvestmentsSelectorSpouseComp";
import { thirdStepCompleted } from "../../../store/actions/savingsAndInvestments";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const SavingsOrInvestmentsSelector = ( props ) => {
    const titles = {
        layoutTitle: 'YOUR SAVINGS & INVESTMENTS',
        sectionTitle: 'Do you have any savings or investments?',
    };

    const title = "Savings or investments for retirement"

    const [next, setNext] = useState(retirementCalcLinks.properties)

    const nextLink = useCallback(() => {
        if(props.you.rrsp.exist || props.spouse.rrsp.exist){
            return retirementCalcLinks.rrspHave
        }else if(props.you.workplaceSavings.exist || props.spouse.workplaceSavings.exist) {
            return retirementCalcLinks.workplaceSavingsHave
        }else if(props.you.lockedIn.exist || props.spouse.lockedIn.exist) {
            return retirementCalcLinks.lockedInHave
        }else if(props.you.tfsa.exist || props.spouse.tfsa.exist){
            return retirementCalcLinks.tfsaHave
        }else if(props.you.nonRegistered.exist || props.spouse.nonRegistered.exist){
            return retirementCalcLinks.nonRegisteredHave
        }else {
            return retirementCalcLinks.properties
        }
    }, [props]);

    useEffect(() => {
        props.thirdStepCompleted(false);
        setNext(nextLink())
    }, [props, nextLink]);

    const onChange = () => {
        setNext(nextLink())
    };

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep3={(1/16)*100}
            active={'step3'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent='savingsOrInvestmentSelector'
                sectionTitle={titles.sectionTitle}
            >

                <InnerLayoutStepContent type={'you'}>
                    <SavingsOrInvestmentsSelectorComp onChange={onChange} />
                </InnerLayoutStepContent>

                {
                    props.isSpouse &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <SavingsOrInvestmentsSelectorSpouseComp onChange={onChange} />
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 prev='/savings-and-investments'
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        you: state.savingsAndInvestments.you,
        spouse: state.savingsAndInvestments.spouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        thirdStepCompleted: (val) => dispatch(thirdStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavingsOrInvestmentsSelector);