import React, { useEffect, useState } from 'react';
import Checkbox from "../../../../UI/Checkbox/Checkbox";
import { connect } from "react-redux";
import {
    updateSavingsOrInvestmentsSpouseData
} from "../../../../../store/actions/savingsAndInvestments";
import classes from '../SavingsOrInvestmentsSelectorYou/SavingsOrInvestmentsSelectorYou.module.scss'

const SavingsOrInvestmentsSelectorSpouseComp = ( props ) => {

    const checkboxes = [
        {
            label: `RRSP`,
            name: 'rrspSpouse'
        },
        {
            label: `Workplace Savings`,
            name: 'workplaceSavingsSpouse'
        },
        {
            label: `Locked-in`,
            name: 'lockedInSpouse'
        },
        {
            label: `TFSA`,
            name: 'tfsaSpouse'
        },
        {
            label: `Non-registered`,
            name: 'nonRegisteredSpouse'
        }
    ]

    const initState = {
        checkedItems: new Map(),
    }

    const [checked, setChecked] = useState(initState)

    const changeValHandler = (e) => {

        const item = e.target.name;
        const isChecked = e.target.checked;

        setChecked({ checkedItems: checked.checkedItems.set(item, isChecked) })

        props.updateSavingsOrInvestmentsSpouseData(checked.checkedItems)
        props.onChange()
    }

    useEffect(() => {
        if (props.rrsp) {
            checked.checkedItems.set('rrspSpouse', true)
        }

        if (props.workplaceSavings) {
            checked.checkedItems.set('workplaceSavingsSpouse', true)
        }

        if (props.lockedIn) {
            checked.checkedItems.set('lockedInSpouse', true)
        }

        if (props.tfsa) {
            checked.checkedItems.set('tfsaSpouse', true)
        }

        if (props.nonRegistered) {
            checked.checkedItems.set('nonRegisteredSpouse', true)
        }

        setChecked({ checkedItems: checked.checkedItems })

    },[checked.checkedItems, props])

    return (
        <div className={classes.CheckboxesWrap}>
            {
                checkboxes.map((input, index) => {
                    return (
                        <Checkbox
                            key={index}
                            onChange={changeValHandler}
                            label={input.label}
                            name={input.name}
                            checked={ checked.checkedItems.get(input.name) }
                        />
                    )
                })
            }
        </div>
    )
};

function mapStateToProps(state) {
    return {
        rrsp: state.savingsAndInvestments.spouse.rrsp.exist,
        workplaceSavings: state.savingsAndInvestments.spouse.workplaceSavings.exist,
        lockedIn: state.savingsAndInvestments.spouse.lockedIn.exist,
        tfsa: state.savingsAndInvestments.spouse.tfsa.exist,
        nonRegistered: state.savingsAndInvestments.spouse.nonRegistered.exist
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateSavingsOrInvestmentsSpouseData : checkedItems => dispatch(updateSavingsOrInvestmentsSpouseData(checkedItems))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavingsOrInvestmentsSelectorSpouseComp);